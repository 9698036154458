import { render, staticRenderFns } from "./HomeHero.vue?vue&type=template&id=df932e82&scoped=true&"
import script from "./HomeHero.vue?vue&type=script&lang=js&"
export * from "./HomeHero.vue?vue&type=script&lang=js&"
import style0 from "./HomeHero.vue?vue&type=style&index=0&id=df932e82&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df932e82",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/src/components/Loaders/Loader.vue').default,HeroSlide: require('/src/components/Home/MainCarousel/HeroSlide.vue').default,HomeHeroTab: require('/src/components/Home/MainCarousel/HomeHeroTab.vue').default,HomeHeroPagination: require('/src/components/Home/MainCarousel/HomeHeroPagination.vue').default})
