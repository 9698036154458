
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      currentSlide: 0,
      loading: true,
      retrievedPosts: [],
      slidesWithPosts: [],
    };
  },
  computed: {
    slides () {
      return this.post.acf?.home_config.carousel.slides;
    },
    settings () {
      return this.post.acf?.home_config.carousel.settings;
    },
    autoplay () {
      return this.settings?.autoplay ?? false;
    },
    autoplayTime () {
      return this.settings?.autoplay_time
        ? parseInt(this.settings.autoplay_time) * 1000
        : 5000;
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.prepareSlides();
    });
  },
  methods: {
    async prepareSlides () {
      // Prepare query

      // Get the 'selected post' IDs to exclude them from the latest news
      const excludePosts = this.slides
        .filter(slide => slide.slide_type === `specific_post`)
        .map(slide => slide?.selected_post?.ID);

      // Get the news slides so we know how many posts to fetch
      const newsSlides = this.slides.filter(
        slide => slide.slide_type === `news`,
      );

      // Get the post types from the carousel settings
      const postTypes = this.settings.latest_news_config.post_types;
      const categories = this.settings.latest_news_config.categories;

      const queryParams = {
        fields: `id,title,image,categories,type,date,slug,acf`,
        posts: {
          post_type: postTypes,
          posts_per_page: newsSlides.length,
          category__in: categories.length ? categories : [], // Returns "false" string if empty so requires check
          post__not_in: excludePosts,
          post_status: `publish`,
        },
        options: {
          date_format: `d F Y`,
        },
      };

      const request = await this.$getPosts(`/posts`, queryParams);
      if (request !== null) {
        this.retrievedPosts = request;
      }

      this.loading = false;
    },
    getRetrievedPost (item) {
      const newsSlides = this.slides.filter(slide => slide.slide_type === `news`);
      const newsSlidesIndex = newsSlides.indexOf(item);
      return this.retrievedPosts[newsSlidesIndex];
    },
    updateSlideNumber (slide) {
      this.currentSlide = slide;
    },
    goToSlide (slideNumber) {
      this.$refs[`hero-carousel`].goToPage(slideNumber);
    },
  },
};
