
export default {
  props: {
    slide: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    retrievedPost: {
      type: Object,
      default: null,
    },
  },
  computed: {
    slideType () {
      return this.slide.slide_type;
    },
    slidePost () {
      return this.slideType === `news`
        ? this.retrievedPost
        : this.slideType === `specific_post`
          ? this.slide.selected_post
          : null;
    },
    title () {
      return this.slideType === `custom`
        ? this.slide?.content.title.replace(/-/g, `‑`) // Replace hypens with non-breaking hyphens
        : this.slideType === `specific_post`
          ? this.slidePost?.post_title.replace(/-/g, `‑`)
          : this.slidePost?.title.replace(/-/g, `‑`);
    },
    date () {
      if (this.slidePost && !this.slide.hide_date) {
        return this.slideType === `specific_post`
          ? this.getDate(this.slidePost.post_date, `DD MMMM YYYY`)
          : this.slideType === `news`
            ? this.slidePost.date
            : false;
      }
      return false;
    },
  },
  methods: {
    changeSlide () {
      this.$emit(`slideChange`, this.index);
    },
  },
};
